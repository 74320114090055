<template>
	<div id="createMedical" v-if="isShow">
		<div class="createMedical_head">
			<van-icon size="13" class="head_icon" name="warning-o" color="#12376B"/>
			<span>提供的资料越完善，匹配到的项目可能会更加适合您。</span>
		</div>
		<!-- <div class="additional" @click="question">没有病历在身边？试试我问你答的方式>></div> -->
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file1" :blList="blList" tips="点击上传病历报告" title="病理报告" imgType="1" :recordId="recordId"></uploadPicture>
		</div>
		
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file2" :blList="blList" tips="点击上传影像报告" title="影像报告(包括CT、超声、MRI、PET)" imgType="2" :recordId="recordId"></uploadPicture>
		</div>
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file3" :blList="blList" tips="点击上传报告结果页" title="基因检测报告" imgType="3" :recordId="recordId"></uploadPicture>
		</div>
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file4" :blList="blList" tips="点击上传出院记录" title="出院记录(或出院小结)" imgType="4" :recordId="recordId"></uploadPicture>
		</div>
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file5" :blList="blList" tips="点击上传血液检查单" title="血液检查单" imgType="5" :recordId="recordId"></uploadPicture>
		</div>
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file6" :blList="blList" tips="点击上传手术记录" title="手术记录" imgType="6" :recordId="recordId"></uploadPicture>
		</div>
		
		<div class="img_box">
			<uploadPicture type="1" chooseBtn="file7" :blList="blList" tips="点击上传病历资料" title="其他病历资料" imgType="7" :recordId="recordId"></uploadPicture>
		</div>
		
		<!-- <div class="commitBtn" @click="commit">
			保存并下一步		
		</div> -->
	</div>
</template>

<script>
	import {
		Icon,
	} from 'vant';
	import uploadPicture from '@/components/medicalRecord/uploadPicture';
	import medicalRecordApi from '@/api/medicalRecord/medicalRecordApi.js';
	export default {
		components: {
			[Icon.name]: Icon,
			uploadPicture,
		},
		data() {
			return {
				recordId:null,
				blList:[],
				isShow:false,
				inquiryId:this.$route.query.inquiryId,
			}
		},
		methods: {
			question(){
				
			},
			//获取当前患者的所有图片信息
			getImgs(){ 
				medicalRecordApi.getBl({inquiryId:this.inquiryId}).then(rs=>{
                    console.log(rs.data.msg)
                    if(typeof(rs.data.msg)!="undefined"){
                        this.blList=[];
                        this.recordId=rs.data.recordId;
                    }else{
                        this.blList=rs.data
                        this.recordId=rs.data[0].recordId;
                    }
                    console.log("recordId is:----"+this.recordId);

                    this.isShow=true;
				})
			},
		},
		mounted() {
			this.getImgs();
		}
		
	}
</script>

<style scoped="scoped" lang="less">
	#createMedical{
		width: 100%;
		// background: red;
		.commitBtn{
			width: 100%;
			height:49px;
			background:rgba(0,96,194,1);
			text-align: center;
			line-height: 49px;
			font-size:17px;			
			font-weight:600;
			color:rgba(255,255,255,1);
			margin-top: 50px;
		}
		.img_box{
			width: 100%;
			padding-left: 15px;
			.img_box_container{
				width: 100%;
				font-size:16px;
				font-weight:600;
				color:rgba(49,49,52,1);
				text-align: left;
			}
			
		}
		.additional{
			width: 100%;
			font-size:14px;
			color:rgba(0,96,194,1);
			padding-left: 15px;
			margin-top: 18px;
		}
		.createMedical_head{
			width: 100%;
			position: relative;
			padding-left: 10px;
			background:rgba(247,247,247,1);
			height:44px;
			.head_icon{
				// line-height: 44px;
				position: absolute;
				top: 16px;
			}
			span{
				display: inline-block;
				height:44px;
				line-height: 44px;
				width: 100%;
				text-align: left;
				font-size:14px;
				color:rgba(18,55,107,1);
				padding-left: 20px;
				vertical-align: text-top;
				// background: red;
			}
			
		}
		
	}
</style>
